import React from 'react'
import "./Main.css"
import m1 from "./m1.png"
import m2 from "./m2.png"
import m3 from "./m3.png"
import m4 from "./m4.png"
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io';
function Main() {
  return (
    <div className='Main'>
      <h2>Other <span style={{color:"#4E62D6"}}>speciality</span></h2>
      <div className="temp-area-home">
 <Link to="/Workshop"> <div className="card">
    <img loading="lazy" src={m1} alt="" />
    <div className="knowmore">
<span>Workshop on managing health for busy professionals</span>
<button>know more <IoIosArrowForward/></button>
    </div>
  </div></Link>
  <Link to="/parents"><div className="card">
    <img loading="lazy" src={m2} alt="" />
    <div className="knowmore">
<span>Workshop on foundation of Kids Health for Parents</span>
<button>know more <IoIosArrowForward/></button>
    </div>
  </div></Link> 
 <Link to="/athletes"> <div className="card">
    <img loading="lazy" src={m3} alt="" />
    <div className="knowmore">
<span>Elite performance for Olympic athletes </span>
<button>know more <IoIosArrowForward/></button>
    </div>
  </div></Link>
  <div className="card">
    <img loading="lazy" src={m4} alt="" />
    <div className="knowmore">
<span>National-level Fitness, Sports & Health Campaign </span>
<button>know more <IoIosArrowForward/></button>
    </div>
  </div>
      
      </div>
    </div>
  )
}

export default Main
