import React from 'react'
import "./Flagship.css"
import rishi from "./rishi.png"
import rishi2 from "./rishi2.png"
import { Link } from 'react-router-dom'
function Flagship() {
  return (
    <div className='Flagship'>
      <div className="flag-txt">
    <h2><span style={{color:"#4E62D6"}}>Flagship</span> program</h2>
    <p>Elevate performance 
        for CEOs and senior leadership.....</p>
       <Link to="/leaders"><button>Know more</button></Link>
      </div>
      <div className="flag-img">
      <img loading="lazy" src={rishi2} alt="" className='rishi-small'  />
    <img loading="lazy" src={rishi} alt="" className='rishi-big'  />
      </div>
    </div>
  )
}

export default Flagship
