import React from 'react'

import { BsArrowRight } from 'react-icons/bs';
function Register() {
  return (
    <div className='Register'>
        <div className="heading-btn">
      <h2><span style={{color:"#000"}}>Secure Your </span><span style={{color:"#4E62D6"}}>Spot!</span></h2>   
      {/* <span className='mins'>Duration - 120 minutes</span>  */}
      <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE"><button>Enroll Today <BsArrowRight/></button></a>
      </div>     
      </div>
  
  )
}

export default Register
