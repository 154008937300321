import React from 'react'
import "./Feed.css"
import i1 from "./img/i1.jpg"
import i2 from "./img/i2.jpg"
import i3 from "./img/i3.jpg"
import i4 from "./img/i4.jpg"
import i5 from "./img/i5.jpg"
import i6 from "./img/i6.jpg"

function Feed() {
  return (
    <div className='Feed'>
    
    <div className="instagram">
    <h2>Join 21k+ parents, athletes, and professionals. <br/> 
    Follow <a target='_' href="https://www.instagram.com/x.rishi/">@x.rishi</a> on Instagram</h2>
   <div className="insta-feed">
    
   <a target='_' href="https://www.instagram.com/p/Cw1XsfyvIC_/?img_index=1">
   <div className="ip"> <img loading="lazy" src={i1} alt="" className='feed-post' /></div></a>

    <a target='_' href="https://www.instagram.com/p/Cwt6AJFvhtL/">
    <div className="ip">
        <img loading="lazy" src={i2} alt="" className='feed-post' />
      </div> 
      </a>

    <a target='_' href="https://www.instagram.com/p/Cwklr9vNyed/">
    <div className="ip">
    <img loading="lazy" src={i3} alt="" className='feed-post' />
    </div> 
    </a>

    <a target='_' href="https://www.instagram.com/p/CwhR-EKtjcn/">
    <div className="ip">
    <img loading="lazy" src={i4} alt="" className='feed-post' />
    </div> 
    </a>

    <a target='_' href="https://www.instagram.com/p/CwfcXW1NjC9/">
    <div className="ip">
    <img loading="lazy" src={i5} alt="" className='feed-post' />
    </div> 
    </a>

    <a target='_' href="https://www.instagram.com/p/CwbmOwStbyk/">
    <div className="ip">
    <img loading="lazy" src={i6} alt="" className='feed-post' />
    </div> 
    </a>

      </div>
      <div className="see-more">
     <a target='_' href="https://www.instagram.com/x.rishi/">
       <p className='more-posts'>See more posts.</p>
     </a>
    </div></div>
      <div className="youtube">    
      <h2>watch 280+ videos about health and wellness <br/> on  YouTube <a target='_' href="https://www.youtube.com/@rishi9566">@Rishi</a></h2>
        <div className="video-area">
        <iframe loading="lazy" className='video' width="1192" height="680" src="https://www.youtube.com/embed/VpMXHsHe0II" title="Reverse type 2 diabetes with movement &amp; nutrition." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe loading="lazy" className='video' width="1192" height="680" src="https://www.youtube.com/embed/sFUvRxoMUYs" title="The evolutionary origin of diabetes." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe loading="lazy" className='video' width="1192" height="680" src="https://www.youtube.com/embed/ZN9vVYl8F-4" title="Smart Carbs -  Carbs Without Wheat and Rice" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      </div>
      <div className="Spotify">
        <h2>Listen to us on Spotify <a target='_' href="https://open.spotify.com/episode/41WZ5eAQG4f96k6ZrWKatm">@Nutritional Narratives</a> </h2>
        <div className="podcasts">
        <iframe
        
        className='spotify-pod'
  title="Embedded Spotify Track"
  src="https://open.spotify.com/embed/episode/41WZ5eAQG4f96k6ZrWKatm?utm_source=generator"
  width="30%"
  height="152"
  allowfullscreen=""
  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
  loading="lazy"
></iframe>

<iframe

        className='spotify-pod'
  title="Embedded Spotify Track"
  src="https://open.spotify.com/embed/episode/7J7jYGvo6qFjnKou520qm7?utm_source=generator"
  width="30%"
  height="152"
  allowfullscreen=""
  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
  loading="lazy"
></iframe>

<iframe

        className='spotify-pod'
  title="Embedded Spotify Track"
  src="https://open.spotify.com/embed/episode/30Yktv3bJzIr14jXYRxgzE?utm_source=generator" 
  width="30%"
  height="152"
  allowfullscreen=""
  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
  loading="lazy"
></iframe>


</div>
      </div>
    </div>
  )
}

export default Feed
