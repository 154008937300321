import React from 'react'
import "./Nav.css"
import { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
function Nav({closeNavi}) {
  const [mypro, setMypro] = useState(false)
  const [aboutme , setAboutMe] = useState(false)
  const openPro=()=>{
    setMypro(!mypro)
  }
  const openAbout=()=>{
    setAboutMe(!aboutme)
  }
  const close=()=>{
    closeNavi()
  }
  return (
    <div className='nav'>
      <ul>
   <li onClick={close}> <Link to="/home"> Home</Link> </li>  

        <li onClick={openAbout}>About Me<span style={{display: aboutme? "none": "flex"  }}><IoIosArrowDown/></span>  <span style={{display: aboutme? "flex" : "none"}}><IoIosArrowUp/></span>  </li>
{aboutme &&(
   <div className='new-items'>
        <li onClick={close}><Link to= "/myStory">My Story</Link></li>
        <li onClick={close}><Link to="/myBlogs">My Blogs</Link></li>
        <li onClick={close}><Link to="/publicSpeaking">Public Speaking</Link></li>
        <li onClick={close}><Link to="/contact" >Contact Me</Link></li> 
   </div>
)}
        <li onClick={openPro}>My Projects<span style={{display: mypro? "none": "flex"  }}><IoIosArrowDown/></span><span style={{display: mypro? "flex" : "none"}}><IoIosArrowUp/></span>  </li>
       { mypro &&(
     <div className='new-items'>
       <li onClick={close}><Link to="/leaders">Leaders</Link></li>
        <li onClick={close}><Link to="/parents">Parents</Link> </li>
        <li onClick={close}><Link to="/athletes">Athletes</Link></li>
        <li onClick={close}><Link to="/Workshop">Busy Professionals</Link></li>
        <li onClick={close}><Link to="/collabrate">Collaborate</Link></li>
       
        </div>
       )}     
       
        <li onClick={close}> <Link to="/media">Media</Link></li>
         
       <li onClick={close}> <a target='_' href="https://www.xtraliving.org/">Xtraliving</a></li>
      </ul>
    </div>
  )
}

export default Nav
