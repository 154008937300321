import React from 'react'
import "./Navbar.css"
import { CgMenuRight } from 'react-icons/cg';
import Nav from './Nav';
import { useState } from 'react';
import { Link } from 'react-router-dom';
function Navbar() {
    const [openNav, setOpenNav] = useState(false)

    const open=()=>{
        setOpenNav(!openNav)
    }
  return (
    <div className='Navbar'>
        <div className="nav-area">
      <Link to="/home" ><img src="/static/media/logo.4529401d93211bf936b5.png" alt="" /></Link> 
      <CgMenuRight onClick={open}/>
    </div>
    {
        openNav&&(
              <Nav closeNavi={open} />
        )
    }
  
    </div>
  )
}

export default Navbar
