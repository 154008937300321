import React from 'react';
import PersonalHeader from '../Personal/Personal/PersonalHeader/PersonalHeader';
import Rishi from './../Personal/Personal/Rishi/Rishi';
import RishiSocial from './../Personal/Personal/RishiSocial/RishiSocial';

import MyBlogs from './../Personal/Personal/MyBlogs/MyBlogs';
import WorkWith from './../Personal/Personal/WorkWith/WorkWith';
//import Believer from '../Personal/Personal/Believer/Believer';
import Flagship from '../Personal/Home/Flagship';
import Main from '../Personal/Home/Main/Main';
import Feed from '../Personal/Personal/Feed/Feed';
import Believers from '../Personal/Home/Believers/Believers';


const Home = () => {
    return (
        <div style={{backgroundColor:"#FBF8F0"}}>
            <PersonalHeader></PersonalHeader>
            <Rishi></Rishi>
            <Flagship></Flagship>
            <Main></Main>
             <Feed/>    
            <RishiSocial></RishiSocial>                   
            <MyBlogs></MyBlogs>           
            <WorkWith></WorkWith>
         <Believers/>
        </div>
    );
};

export default Home;