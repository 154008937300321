import React from "react";
import physicalFitness from "../../../../../assets/Physical-fitness.png";
import arrow from '../../../../../assets/Arrow.png'

const PhysicalFitness = () => {
  return (
    <div className=" bg-[#FBF8F0] pb-12">
      <div className="mx-auto w-3/4">
        <img src={physicalFitness} alt="" />

        <div
          tabIndex={0}
          className="collapse collapse-open bg-[#FBF8F0] mt-10 "
        >
          <div className="collapse-title text-xl font-medium">
            <div className="border-left flex items-center font-bold">
              <p className="ml-3 text-[#000000]">
                Importance of physical fitness in senior women
              </p>
            </div>
          </div>
          <div className="collapse-content">
            <p className="text-justify mt-4">
              Haven’t we all heard about the benefits and the importance of
              physical fitness in our lives? But a majority of the time physical
              fitness remains as a topic of discussion only among the relatively
              young population of our society. Rarely do we find the need to
              include and encourage the seniors of our population to be
              physically fit. However, it is time to address and recognise the
              benefits of exercising and physical activities among the senior
              women of our lives. In this article, we shall be helping you to do
              exactly that.
            </p>
          </div>
        </div>

        <div
          tabIndex={0}
          className="collapse collapse-open bg-[#FBF8F0] mt-10 "
        >
          <div className="collapse-title text-xl font-medium">
            <div className="border-left flex items-center font-bold">
              <p className="ml-3 text-[#000000]">WHAT IS PHYSICAL FITNESS?</p>
            </div>
          </div>
          <div className="collapse-content">
            <p className="text-justify mt-4">
              Physical fitness can be defined to be a movement of the body which
              results in energy expenditure. It generally includes exercise,
              sports, and physical activities performed as part of daily living,
              occupation, leisure, or active transportation. Exercise is a
              subcategory of physical fitness which is a planned, structured,
              and repetitive action, thus helping one to improve their fitness
              levels. Physical function is the capacity of an individual to
              perform the physical activities which also reflect motor function
              and control, physical fitness, etc. A paper published in the year
              2018 by Birgitta Langhammer mentions that “the health benefits of
              Physical Fitness are well documented with higher levels and
              greater frequency of it being associated with reduced risk and
              improved health in several key areas.” Chances of cardiovascular
              diseases, strokes, diabetes, certain types of cancer gets delayed
              while also showing positive signs of development in one’s mental
              health through regular engagement with physical activities.
            </p>
          </div>
        </div>

        <div
          tabIndex={0}
          className="collapse collapse-open bg-[#FBF8F0] mt-10 "
        >
          <div className="collapse-title text-xl font-medium">
            <div className="border-left flex items-center font-bold">
              <p className="ml-3 text-[#000000]">
                IMPORTANCE OF INCLUDING SENIOR WOMEN?
              </p>
            </div>
          </div>
          <div className="collapse-content">
            <p className="text-justify mt-4">
              Even though discourses around fitness, healthy body and physical
              activity are growing rapidly in our country, these are still
              topics that are largely avoided in discussions. Further, physical
              training and workouts for women and especially relatively elderly
              women are considered taboos. However, involving such senior women
              in physical activities and attempting to make their bodies fitter
              and healthier is the need of the hour. According to a study by
              WHO, the global rate of physical inactivity is 21.4%. This
              translates to one in every four to five adults being physically
              inactive. Inactivity and ageing increase the risk of chronic
              diseases, alterations in body composition resulting in an increase
              in the percentage of body fat and a decline in lean body mass.
              Thus, significant loss of health takes place due to inactivity.
              Exercising can contribute to the maintenance of quality life,
              health, and reduction in falls among older people in general and
              senior women in particular. Various reports by leading researchers
              have shown that a sedentary lifestyle affects the bone density,
              muscle strength, joints and the natural abilities of the lungs and
              the heart. The amount and size of muscle fibres, the bone density,
              and the strength of the joint start declining by the time people
              turn 40. These are the normal order of behaviours for the human
              body. Even though with age the human body is known to decay, how
              much we let our body fitness decline, is however definitely in our
              hands. Maintaining a physically fit lifestyle, performing physical
              activities regularly, walking up and down the road while running
              errands are all some easy yet effective ways of maintaining one’s
              fitness levels.
            </p>
          </div>
        </div>

        <div
          tabIndex={0}
          className="collapse collapse-open bg-[#FBF8F0] mt-10 "
        >
          <div className="collapse-title text-xl font-medium">
            <div className="border-left flex items-center font-bold">
              <p className="ml-3 text-[#000000]">OVERCOMING OBSTACLES OF AGE</p>
            </div>
          </div>
          <div className="collapse-content">
            <p className="text-justify mt-4">
              Truth be told, beginning or maintaining a training routine can be
              a challenging task at any age, and it becomes a tad bit more
              difficult the more we age. However, age shouldn’t be a reason to
              be discouraged to begin your exercise regime. If you’ve never
              exercised before, you may not know where to begin, or you may
              think you’re too old or frail and can never live up to the
              standards you set for yourselves when you were younger. These
              reasons might be valid enough to begin your fitness journey
              slowly, but they aren’t reasons to stop your fitness journey.
              Sometimes, we end up believing that the more we age, the more at
              risk we are to fall due to exercising. Regular exercises improve
              strength and stamina, prevents loss of bone mass and improves
              balance, thus reducing risks of falling. Further, as we age our
              bodies tend to develop aches and pains. Hence, a lot of times
              elderly people refuse to exercise thinking that their pains will
              be aggravated through exercises. However, regular exercises help
              to manage such pains and improve strength and virility. Changes in
              hormones, metabolism, bone density and muscle mass inevitably
              decline with age. But that doesn’t mean you can no longer derive a
              sense of achievement from physical activity or improve your
              health. Exercise is good for you, irrespective of age. Just
              remember, a sedentary lifestyle takes a much greater toll on
              health and fitness than natural ageing.
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-8">
        <img src={arrow} alt="" />
      </div>
    </div>
  );
};

export default PhysicalFitness;
