import React from 'react';
import BlogHeader from './ArticleHeader/BlogHeader';

const Blog = () => {
    return (
        <div>
             <BlogHeader></BlogHeader>
        </div>
    );
};

export default Blog;